var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vx-card',{staticClass:"mb-base",staticStyle:{"margin-top":"20px"}},[_c('div',{staticClass:"flex items-center mb-5"},[_c('h2',{staticClass:"mb-0"},[_vm._v("Activities")]),((_vm.activity && _vm.activity.isSpontaneous == true))?_c('span',{class:'ml-auto font-bold mr-10 color-spontaneous'},[_vm._v(" Spontaneous ")]):_c('span',{class:'ml-auto font-bold mr-10 ' +
            (_vm.activity.status[0].slug === 'new'
              ? ' color-green'
              : ' color-grey')},[_vm._v(" "+_vm._s(_vm.activity.status[0].name)+" ")])]),_c('div',{staticClass:"overflow-auto"},[_c('table',{staticClass:"table learning-activity-table",attrs:{"width":"100%"}},[_c('thead',[_c('tr',[_c('th',[_vm._v("Activity")]),_c('th',[_vm._v("Description")]),_c('th',{staticClass:"text-center"},[_vm._v("Core Skill")]),_c('th',{staticClass:"text-center"},[_vm._v("Ways of Learning")]),_c('th',{staticClass:"text-center"},[_vm._v("STREAM")])])]),_c('tbody',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.activity.name))]),_c('td',[_vm._v(_vm._s(_vm.activity.description))]),_c('td',{staticClass:"text-center"},[(_vm.activity.coreSkill)?_c('img',{staticClass:"inline-block h-12 object-contain",attrs:{"src":_vm.activity.coreSkill[0].icon
                    ? _vm.activity.coreSkill[0].icon
                    : _vm.$defaultImage,"alt":""}}):_vm._e(),_c('p',[_vm._v(" "+_vm._s(_vm.activity.coreSkill ? _vm.activity.coreSkill[0].name : "")+" ")])]),_c('td',{staticClass:"text-center"},[(_vm.activity.waysOfLearning)?_c('img',{staticClass:"inline-block h-12 object-contain",attrs:{"src":_vm.activity.waysOfLearning[0].icon
                    ? _vm.activity.waysOfLearning[0].icon
                    : _vm.$defaultImage,"alt":""}}):_vm._e(),_c('p',[_vm._v(" "+_vm._s(_vm.activity.waysOfLearning ? _vm.activity.waysOfLearning[0].name : "")+" ")])]),_c('td',{staticClass:"text-center"},[(_vm.activity.streams)?_c('img',{staticClass:"inline-block h-12 object-contain",attrs:{"src":_vm.activity.streams[0].icon
                    ? _vm.activity.streams[0].icon
                    : _vm.$defaultImage,"alt":""}}):_vm._e(),_c('p',[_vm._v(_vm._s(_vm.activity.streams ? _vm.activity.streams[0].name : ""))])])])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }