<template>
  <div>
    <vx-card class="mb-base" style="margin-top: 20px;">
      <div class="flex items-center mb-5">
        <h2 class="mb-0">Activities</h2>
        <span
          :class="'ml-auto font-bold mr-10 color-spontaneous'"
          v-if="(activity && activity.isSpontaneous == true)"
        >
        Spontaneous
        </span>
        <span
          :class="
            'ml-auto font-bold mr-10 ' +
              (activity.status[0].slug === 'new'
                ? ' color-green'
                : ' color-grey')
          "
          v-else
        >
          {{ activity.status[0].name }}
        </span>
      </div>
      <div class="overflow-auto">
        <table class="table learning-activity-table" width="100%">
          <thead>
            <tr>
              <th>Activity</th>
              <th>Description</th>
              <th class="text-center">Core Skill</th>
              <th class="text-center">Ways of Learning</th>
              <th class="text-center">STREAM</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>{{ activity.name }}</th>
              <td>{{ activity.description }}</td>
              <td class="text-center">
                <img
                  v-if="activity.coreSkill"
                  :src="
                    activity.coreSkill[0].icon
                      ? activity.coreSkill[0].icon
                      : $defaultImage
                  "
                  alt
                  class="inline-block h-12 object-contain"
                />
                <p>
                  {{ activity.coreSkill ? activity.coreSkill[0].name : "" }}
                </p>
              </td>
              <td class="text-center">
                <img
                  v-if="activity.waysOfLearning"
                  :src="
                    activity.waysOfLearning[0].icon
                      ? activity.waysOfLearning[0].icon
                      : $defaultImage
                  "
                  alt
                  class="inline-block h-12 object-contain"
                />
                <p>
                  {{
                    activity.waysOfLearning
                      ? activity.waysOfLearning[0].name
                      : ""
                  }}
                </p>
              </td>
              <td class="text-center">
                <img
                  v-if="activity.streams"
                  :src="
                    activity.streams[0].icon
                      ? activity.streams[0].icon
                      : $defaultImage
                  "
                  alt
                  class="inline-block h-12 object-contain"
                />
                <p>{{ activity.streams ? activity.streams[0].name : "" }}</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </vx-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activity: ""
    };
  },
  props: ["selectedActivity"],
  mounted() {
    this.activity = this.selectedActivity;
  },
  watch: {
    selectedActivity(res) {
      this.activity = res;
    }
  }
};
</script>
<style>
.color-green {
  color: green;
}

.color-grey {
  color: grey;
}

.color-spontaneous {
  color: #28388d;
}
</style>
